import { Box } from '@mui/material';

export default function TGSimpleSlider({
  children,
  pb = '5px',
  gap = '16px',
  widthFilter,
  displaySlider = 'flex',
  ...props
}) {
  return (
    <Box
      sx={{
        'display': displaySlider,
        'gap': gap,
        'scrollSnapType': 'x mandatory',
        'pb': pb,
        'overflowX': { xs: 'auto', md: 'initial' },
        'width': widthFilter || '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        'scrollbarWidth': 'none',
        '-ms-overflow-style': 'none',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
