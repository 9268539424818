// menuItems.js
import { tools } from 'assets/svgs';
import { integrations } from 'assets/svgs';
import { people } from 'assets/svgs';
import {
  home,
  events,
  products,
  Indicacoes,
  LayoutEmail,
  vendas,
  usuario,
  estrela,
  settings,
  finance,
  checkIn,
  chartIcon,
} from 'assets/svgs';

const menuItems = [
  {
    text: 'Início',
    icon: home,
    to: '/',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Eventos',
    icon: events,
    to: '',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
    children: [
      {
        text: 'Novo evento',
        to: 'event/add',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
      {
        text: 'Cadastrados',
        to: 'event/registered',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
      {
        text: 'Participantes',
        to: 'attendee',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
      {
        text: 'Cupom',
        to: 'event/coupon',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
    ],
  },
  // {
  //   text: 'Produtos v2',
  //   icon: products,
  //   to: 'produto/index',
  //   isBeta: true,
  //   adm: true,
  //   roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
  // },
  {
    text: 'Produtos',
    icon: products,
    to: 'produto',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Layout de e-mail',
    icon: LayoutEmail,
    to: 'mailing',
    adm: true,
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
  },
  {
    text: 'Financeiro',
    icon: finance,
    to: 'financial/summary',
    adm: false,
    roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    // children: [
    //   {
    //     text: 'Minhas vendas',
    //     to: 'financial/sales',
    //     adm: true,
    //     roles: [
    //       'PRODUTOR-OPERACIONAL',
    //       'PRODUTOR-ADMIN',
    //       'PRODUTOR-FINANCEIRO',
    //     ],
    //   },
    //   //{
    //   //  text: 'Financeiro',
    //   //  to: 'financial/summary',
    //   //  adm: true,
    //   //  roles: [
    //   //    'PRODUTOR-OPERACIONAL',
    //   //    'PRODUTOR-ADMIN',
    //   //    'PRODUTOR-FINANCEIRO',
    //   //  ],
    //   //},
    //   {
    //     text: 'Relatórios',
    //     to: 'relatorio/enotas',
    //     adm: true,
    //     roles: [
    //       'PRODUTOR-OPERACIONAL',
    //       'PRODUTOR-ADMIN',
    //       'PRODUTOR-FINANCEIRO',
    //     ],
    //   },
    //   {
    //     text: 'Carteira',
    //     to: 'financial/wallet',
    //     adm: true,
    //     roles: [
    //       'PRODUTOR-OPERACIONAL',
    //       'PRODUTOR-ADMIN',
    //       'PRODUTOR-FINANCEIRO',
    //     ],
    //   },
    //   {
    //     text: 'Reembolso',
    //     to: 'financial/refunds',
    //     adm: true,
    //     roles: [
    //       'PRODUTOR-OPERACIONAL',
    //       'PRODUTOR-ADMIN',
    //       'PRODUTOR-FINANCEIRO',
    //     ],
    //   },
    // ],
  },
  {
    text: 'Vendas',
    icon: vendas,
    //to: '/financeiro',
    adm: true,
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    children: [
      {
        text: 'Minhas vendas',
        to: 'report/sales',
        adm: true,
        roles: [
          'PRODUTOR-OPERACIONAL',
          'PRODUTOR-ADMIN',
          'PRODUTOR-FINANCEIRO',
        ],
      },
      {
        text: 'Recuperadas',
        to: 'report/recoveredsales',
        adm: true,
        roles: [
          'PRODUTOR-OPERACIONAL',
          'PRODUTOR-ADMIN',
          'PRODUTOR-FINANCEIRO',
        ],
      },
      {
        text: 'Assinaturas',
        to: 'financeiro/vendas',
        adm: true,
        roles: [
          'PRODUTOR-OPERACIONAL',
          'PRODUTOR-ADMIN',
          'PRODUTOR-FINANCEIRO',
        ],
      },
      {
        text: 'Reembolso',
        to: 'financial/reimbursement',
        adm: true,
        roles: [
          'PRODUTOR-OPERACIONAL',
          'PRODUTOR-ADMIN',
          'PRODUTOR-FINANCEIRO',
        ],
      },
      {
        text: 'Payflex',
        to: 'report/payflexsales',
        adm: true,
        roles: [
          'PRODUTOR-OPERACIONAL',
          'PRODUTOR-ADMIN',
          'PRODUTOR-FINANCEIRO',
        ],
      },
    ],
  },
  {
    text: 'Afiliados',
    icon: people,
    to: '',
    adm: true,
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
    children: [
      {
        text: 'Meus Programas',
        to: 'afiliados/index',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
      {
        text: 'Minhas afiliações',
        to: 'afiliados/afiliacoes',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
    ],
  },
  {
    text: 'Relatórios',
    icon: chartIcon,
    to: 'relatorios',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
    adm: true,
  },
  {
    text: 'Check-in',
    icon: checkIn,
    adm: true,
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
    children: [
      {
        text: 'Dashboard',
        to: 'report/dashboard',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
      {
        text: 'Participantes',
        to: 'attendee',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
      {
        text: 'Credenciamento',
        to: 'operator',
        adm: true,
        roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
      },
    ],
  },
  {
    text: 'Integrações',
    icon: integrations,
    to: 'integrations/configuration',
    adm: true,
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
  },
  {
    text: 'Automações',
    icon: tools,
    to: 'integrations/automation',
    adm: true,
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
  },

  {
    text: 'Usuários',
    icon: usuario,
    adm: true,
    roles: [],
    children: [
      {
        text: 'Cadastrados',
        to: 'user',
        adm: true,
      },
      {
        text: 'Novo usuário',
        to: 'user/add',
        adm: true,
      },
    ],
  },
  {
    text: 'Produtoras',
    icon: finance,
    adm: true,
    roles: [],
    children: [
      {
        text: 'Cadastradas',
        to: 'producer',
        adm: true,
      },
      {
        text: 'Nova produtora',
        to: 'producer/new',
        adm: true,
      },
      {
        text: 'Taxas',
        to: 'rate',
        adm: true,
      },
    ],
  },
  {
    text: 'BackOffice',
    icon: estrela,
    adm: true,
    roles: [],
    children: [
      {
        text: 'Relatório',
        to: 'backoffice/sales',
        adm: true,
      },
      {
        text: 'Repasses',
        to: 'backoffice/withdraw/producer',
        adm: true,
      },
      {
        text: 'Estorno',
        to: 'backoffice/reversal',
        adm: true,
      },
      {
        text: 'Reembolso',
        to: 'financial/reimbursement',
        adm: true,
      },
    ],
  },

  {
    text: 'Configurações',
    icon: settings,
    adm: true,
    roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    children: [
      {
        text: 'Cadastro',
        to: 'producer/edit?uuid=#UUIDPRODUTORA#',
        adm: true,
        roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
      },
      {
        text: 'Dados Bancários',
        to: 'databank/add',
        adm: true,
        roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
      },
      {
        text: 'Usuários',
        to: 'user',
        adm: true,
        roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
      },
      {
        text: 'Smtp',
        to: 'smtp',
        adm: true,
        roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
      },
      // {
      //   text: 'Maquininha',
      //   to: 'machine/settings',
      //   adm: true,
      //   roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
      // },
    ],
  },

  // {
  //   text: 'Configurações',
  //   icon: settings,
  //   to: 'producer',
  //   adm: false,
  //   roles: ['PRODUTOR-ADMIN'],
  //   children: [
  //     {
  //       text: 'Cadastro',
  //       to: 'producer/add',
  //       adm: false,
  //     },

  //     {
  //       id: 23,
  //       text: 'Dados bancários',
  //       icon: 'bi bi-bank',
  //       route: 'databank/add',
  //       show: true,
  //       roles: ['PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
  //     },
  //     // {
  //     //   id: 24,
  //     //   text: "Taxas",
  //     //   icon: "bi bi-percent",
  //     //   route: "databank/rates",
  //     //   show: true,
  //     //   roles: ["PRODUTOR-ADMIN", "PRODUTOR-FINANCEIRO"],
  //     // },
  //     {
  //       id: 25,
  //       text: 'Usuários',
  //       icon: 'bi bi-people',
  //       route: 'user/index',
  //       show: true,
  //       roles: ['PRODUTOR-ADMIN'],
  //     },
  //     {
  //       id: 43,
  //       text: 'Smtp',
  //       icon: 'bi bi-envelope',
  //       route: 'smtp/index',
  //       show: true,
  //       adm: true,
  //       roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN'],
  //     },
  //   ],
  // },
  {
    text: 'Eventos',
    icon: events,
    adm: true,
    roles: ['PREFEITURA'],
    children: [
      {
        text: 'Participantes',
        to: 'attendee',
        adm: true,
        roles: ['PREFEITURA'],
      },
      {
        text: 'Vendas',
        to: 'report/sales',
        adm: true,
        roles: ['PREFEITURA'],
      },
    ],
  },
];

export default menuItems;
