export const CopyIcon = ({ width = 24, height = 24, color = '#FAFAFA' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7659_28494)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.77351 9.77351C3.23737 10.3096 2.89961 11.2384 2.89961 12.8996V17.0996C2.89961 18.7608 3.23737 19.6896 3.77351 20.2257C4.30964 20.7619 5.23838 21.0996 6.89961 21.0996H11.0996C12.7608 21.0996 13.6896 20.7619 14.2257 20.2257C14.7619 19.6896 15.0996 18.7608 15.0996 17.0996V12.8996C15.0996 11.2384 14.7619 10.3096 14.2257 9.77351C13.6896 9.23737 12.7608 8.89961 11.0996 8.89961H6.89961C5.23838 8.89961 4.30964 9.23737 3.77351 9.77351ZM2.50071 8.50071C3.53958 7.46185 5.06084 7.09961 6.89961 7.09961H11.0996C12.9384 7.09961 14.4596 7.46185 15.4985 8.50071C16.5374 9.53958 16.8996 11.0608 16.8996 12.8996V17.0996C16.8996 18.9384 16.5374 20.4596 15.4985 21.4985C14.4596 22.5374 12.9384 22.8996 11.0996 22.8996H6.89961C5.06084 22.8996 3.53958 22.5374 2.50071 21.4985C1.46185 20.4596 1.09961 18.9384 1.09961 17.0996V12.8996C1.09961 11.0608 1.46185 9.53958 2.50071 8.50071Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.77351 3.77351C9.23737 4.30964 9.00039 5.53839 9.00039 7.19962H7.80039H7.09961C7.09961 5.36085 7.46185 3.53958 8.50071 2.50071C9.53958 1.46185 11.0608 1.09961 12.8996 1.09961H17.0996C18.9384 1.09961 20.4596 1.46185 21.4985 2.50071C22.5374 3.53958 22.8996 5.06084 22.8996 6.89961V11.0996C22.8996 12.9384 22.5374 14.4596 21.4985 15.4985C20.4596 16.5374 18.6392 16.8996 16.8004 16.8996V16.1996V15.0996C18.4616 15.0996 19.6896 14.7619 20.2257 14.2257C20.7619 13.6896 21.0996 12.7608 21.0996 11.0996V6.89961C21.0996 5.23838 20.7619 4.30964 20.2257 3.77351C19.6896 3.23737 18.7608 2.89961 17.0996 2.89961H12.8996C11.2384 2.89961 10.3096 3.23737 9.77351 3.77351Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7659_28494">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
