export const InfoIcon = ({ size = 24, color = '#222222', bgcolor }) => {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.9992 22.2992C5.0571 22.2992 0.199219 17.4413 0.199219 11.4992C0.199219 5.5571 5.0571 0.699219 10.9992 0.699219C16.9413 0.699219 21.7992 5.5571 21.7992 11.4992C21.7992 17.4413 16.9413 22.2992 10.9992 22.2992ZM11.9249 15.2021C11.9249 15.7133 11.5105 16.1278 10.9992 16.1278C10.488 16.1278 10.0735 15.7133 10.0735 15.2021V10.2649C10.0735 9.75367 10.488 9.33922 10.9992 9.33922C11.5105 9.33922 11.9249 9.75367 11.9249 10.2649V15.2021ZM9.7577 6.56208C9.7577 7.24375 10.3103 7.79636 10.992 7.79636H11.0031C11.6847 7.79636 12.2374 7.24375 12.2374 6.56208C12.2374 5.8804 11.6847 5.32779 11.0031 5.32779H10.992C10.3103 5.32779 9.7577 5.8804 9.7577 6.56208Z'
        fill={bgcolor}
      />
    </svg>
  );
};
